import { runInAction } from "mobx";
import root from "../../";
import {
    createUsr,
    getUsers,
    getUsrById,
    saveUsr,
} from "../../../modules/api-service";
import { doneTask, startTask } from "../../work-progress/actions";
import { BugCrash } from "../../../modules/bug-crash";
import { addError } from "../../error-info/actions";
import { Usr } from "../../../modules/cls/usr";

export const requireUserById = (id) => {
    let _usr = root.users[id];
    if (!_usr) {
        _usr = new Usr(id);
        root.users[id] = _usr;
    }

    return _usr;
};

export const actLoadUsers = async () => {
    let _wpId;

    try {
        _wpId = startTask(`actLoadUsers`);

        const _srvResp = await getUsers();

        runInAction(() => {
            for (const _srvItm of _srvResp.itms) {
                const _usr = requireUserById(_srvItm.id);
                _usr.fromSrv(_srvItm);
            }
        });
    } catch (err) {
        addError({
            lbl: "load_users",
        });
        BugCrash.notifyExt("actLoadUsers", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actLoadUsr = async (usrId) => {
    let _wpId;

    try {
        _wpId = startTask(`actLoadUsr_${usrId}`);

        const _srvResp = await getUsrById(usrId);

        runInAction(() => {
            const _usr = requireUserById(usrId);
            _usr.fromSrv(_srvResp);
        });
    } catch (err) {
        addError({
            lbl: "load_user",
        });
        BugCrash.notifyExt("actLoadUsr", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actSaveUsr = async (usrId, data) => {
    let _wpId;

    try {
        _wpId = startTask(`actSaveUsr_${usrId}`);

        await saveUsr(usrId, data);
        await actLoadUsr(usrId);
    } catch (err) {
        addError({
            lbl: "save_user",
        });
        BugCrash.notifyExt("actSaveUsr", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};

export const actCreateUsr = async (email, password) => {
    let _wpId;

    try {
        _wpId = startTask(`actCreateUsr_${email}`);

        const { id } = await createUsr(email, password);
        await actLoadUsr(id);

        return id;
    } catch (err) {
        addError({
            lbl: "save_user",
        });
        BugCrash.notifyExt("actCreateUsr", err);
    } finally {
        if (_wpId) {
            doneTask(_wpId);
        }
    }
};
