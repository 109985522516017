import React, { Suspense, lazy, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route } from "react-router-dom";
import { actLoadUsers } from "../store/users/actions";
import { actLoadClnts } from "../store/clnts/actions";
import { actLoadClntAsps } from "../store/clnt-asps/actions";

const CmdsMain = lazy(() => import("./cmds/CmdsMain"));
const UsersMain = lazy(() => import("./users/UsersMain"));

const DashboardMain = lazy(() => import("./dashboard/DashboardMain"));

const WaitFallback = () => {
    const [t] = useTranslation("common");
    return <div>{t("general.bitteWarten")}</div>;
};

function RouteDispatcher() {
    useEffect(() => {
        Promise.all([actLoadUsers(), actLoadClnts(), actLoadClntAsps()]).catch(
            console.error
        );
    }, []);

    return (
        <Suspense fallback={<WaitFallback />}>
            <Routes>
                <Route exact path="cmds/*" element={<CmdsMain />} />
                <Route exact path="users/*" element={<UsersMain />} />
                <Route path="*" element={<DashboardMain />} />
            </Routes>
        </Suspense>
    );
}

export default memo(RouteDispatcher);
