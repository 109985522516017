import { observable } from "mobx";
import { requireUserById } from "../users/actions";

const _store = observable({
    uid: "-",

    get IsInit() {
        return this.uid !== "-";
    },

    get AuthUid() {
        return this.uid !== "-" ? this.uid : "";
    },

    get AuthUser() {
        if (!this.IsInit) {
            return null;
        }

        return requireUserById(this.AuthUid);
    },

    get IsAdmin() {
        if (!this.IsInit) {
            return false;
        }

        const _usr = requireUserById(this.AuthUid);

        return _usr.adm > 0;
    },
});

export default _store;
