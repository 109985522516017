import { action, computed, makeObservable, observable } from "mobx";

export const RemFileOwnerTypes = {
    CMD: "cmd",
    ABR: "abr",
    TB: "tb",
    VAO: "vao",
};

export class RemFile {
    _id = "";
    _ownId = "";
    _ownType = "";
    _origName = "";
    _origTyp = "";
    _typ = "";
    _ts = -1;
    _src = "";
    _com = "";
    _usr = "";

    _signedUrl = "";
    _signedUrlExpires = -1;
    _signedUrlLoading = false;

    _signedUrlM = "";
    _signedUrlMExpires = -1;
    _signedUrlMLoading = false;

    _signedUrlL = "";
    _signedUrlLExpires = -1;
    _signedUrlLLoading = false;

    constructor(id) {
        this._id = id;

        makeObservable(this, {
            _id: observable,
            _ownId: observable,
            _ownType: observable,
            _origName: observable,
            _origTyp: observable,
            _typ: observable,
            _ts: observable,
            _src: observable,
            _com: observable,
            _usr: observable,

            _signedUrl: observable,
            _signedUrlExpires: observable,
            _signedUrlLoading: observable,

            _signedUrlM: observable,
            _signedUrlMExpires: observable,
            _signedUrlMLoading: observable,

            _signedUrlL: observable,
            _signedUrlLExpires: observable,
            _signedUrlLLoading: observable,

            id: computed,
            ownId: computed,
            ownType: computed,
            origName: computed,
            origTyp: computed,
            typ: computed,
            ts: computed,
            src: computed,
            com: computed,
            usr: computed,

            signedUrl: computed,
            signedUrlExpires: computed,
            signedUrlLoading: computed,

            signedUrlM: computed,
            signedUrlMExpires: computed,
            signedUrlMLoading: computed,

            signedUrlL: computed,
            signedUrlLExpires: computed,
            signedUrlLLoading: computed,

            fromSrv: action.bound,

            setSignedUrl: action.bound,
            setSignedUrlExpires: action.bound,
            setSignedUrlLoading: action.bound,

            setSignedUrlM: action.bound,
            setSignedUrlMExpires: action.bound,
            setSignedUrlMLoading: action.bound,

            setSignedUrlL: action.bound,
            setSignedUrlLExpires: action.bound,
            setSignedUrlLLoading: action.bound,
        });
    }

    get id() {
        return this._id;
    }
    get ownId() {
        return this._ownId;
    }
    get ownType() {
        return this._ownType;
    }
    get origName() {
        return this._origName;
    }
    get origTyp() {
        return this._origTyp;
    }
    get typ() {
        return this._typ;
    }

    get ts() {
        return this._ts;
    }
    get src() {
        return this._src;
    }
    get com() {
        return this._com;
    }
    get usr() {
        return this._usr;
    }

    get signedUrl() {
        return this._signedUrl;
    }
    get signedUrlExpires() {
        return this._signedUrlExpires;
    }
    get signedUrlLoading() {
        return this._signedUrlLoading;
    }

    get signedUrlM() {
        return this._signedUrlM;
    }
    get signedUrlMExpires() {
        return this._signedUrlMExpires;
    }
    get signedUrlMLoading() {
        return this._signedUrlMLoading;
    }

    get signedUrlL() {
        return this._signedUrlL;
    }
    get signedUrlLExpires() {
        return this._signedUrlLExpires;
    }
    get signedUrlLLoading() {
        return this._signedUrlLLoading;
    }

    setSignedUrl(val) {
        if (this._signedUrl !== val) {
            this._signedUrl = val;
        }
    }
    setSignedUrlExpires(val) {
        if (this._signedUrlExpires !== val) {
            this._signedUrlExpires = val;
        }
    }
    setSignedUrlLoading(val) {
        if (this._signedUrlLoading !== val) {
            this._signedUrlLoading = val;
        }
    }

    setSignedUrlM(val) {
        if (this._signedUrlM !== val) {
            this._signedUrlM = val;
        }
    }
    setSignedUrlMExpires(val) {
        if (this._signedUrlMExpires !== val) {
            this._signedUrlMExpires = val;
        }
    }
    setSignedUrlMLoading(val) {
        if (this._signedUrlMLoading !== val) {
            this._signedUrlMLoading = val;
        }
    }

    setSignedUrlL(val) {
        if (this._signedUrlL !== val) {
            this._signedUrlL = val;
        }
    }
    setSignedUrlLExpires(val) {
        if (this._signedUrlLExpires !== val) {
            this._signedUrlLExpires = val;
        }
    }
    setSignedUrlLLoading(val) {
        if (this._signedUrlLLoading !== val) {
            this._signedUrlLLoading = val;
        }
    }

    fromSrv(srvJson) {
        //console.log("RemFile::fromSrv(srvJson)", srvJson);

        this._id = srvJson.id;
        this._ownId = srvJson.ownerId;
        this._ownType = srvJson.ownerType;
        this._origName = srvJson.on;
        this._origTyp = srvJson.ot;
        this._typ = srvJson.typ;
        this._ts = srvJson.ts;
        this._src = srvJson.src;
        this._com = srvJson.com;
        this._usr = srvJson.usr;
    }
}
