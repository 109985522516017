import { orderBy, pick } from "lodash";
import {
    action,
    computed,
    makeObservable,
    observable,
    toJS,
    values,
} from "mobx";
import { RemFileOwnerTypes } from "./rem-file";
import root from "../../store";

const TbItmReasonsLbls = {};
export const TbItmReasons = {
    R1: 1,
    R2: 2,
    R3: 3,
    R4: 4,
    R5: 5,
    R6: 6,
    R7: 7,
    R8: 8,
    R9: 9,
    R10: 10,
    R11: 11,

    UNK: 99,
};

export const getTbItmReasonLbl = (tbItmReason, t) => {
    let _lbl = TbItmReasonsLbls[tbItmReason];
    if (_lbl) {
        return _lbl;
    }

    _lbl = t(`cmd_tb_itm_reasons.${tbItmReason}`);
    TbItmReasonsLbls[tbItmReason] = _lbl;

    return _lbl;
};

export const getTbItmReasonsOrdered = () => {
    return [
        TbItmReasons.R1,
        TbItmReasons.R2,
        TbItmReasons.R3,
        TbItmReasons.R4,
        TbItmReasons.R5,
        TbItmReasons.R6,
        TbItmReasons.R7,
        TbItmReasons.R8,
        TbItmReasons.R9,
        TbItmReasons.R10,
        TbItmReasons.R11,

        TbItmReasons.UNK,
    ];
};

const TbItmTypesLbls = {};
export const TbItmTypes = {
    GRUBE: 1,
    GRABEN: 2,
    LOCH_WAND: 3,

    ROHR_VERLEGT: 4,
    KABEL: 5,

    SOCLU: 6,

    GF: 7,
};

export const getTbItmTypLbl = (tbItmTyp, t) => {
    let _lbl = TbItmTypesLbls[tbItmTyp];
    if (_lbl) {
        return _lbl;
    }

    _lbl = t(`cmd_tb_itm_types.${tbItmTyp}`);
    TbItmTypesLbls[tbItmTyp] = _lbl;

    return _lbl;
};

const TbItmModelsLbls = {};
export const TbItmModels = {
    RO_50: 1,
    RO_110: 2,
    RO_SNRVE_1x7: 3,
    RO_SNRVE_2x7: 4,
    RO_SNRVE_8x7: 5,
    RO_SNRVE_22x7: 6,
    RO_SNRVE_3x12: 7,
    RO_SNRVE_7x12: 8,

    UNK: 99,

    KA_DA_10: 101,
    KA_DA_20: 102,
    KA_DA_50: 103,
    KA_DA_100: 104,
    KA_DA_200: 105,
    KA_DA_300: 106,
    KA_DA_500: 107,
    KA_DA_1000: 108,
    KA_DA_1500: 109,
    KA_DA_2000: 110,

    GF_MICRO_1x4: 201,
    GF_MICRO_1x12: 202,
    GF_MINI_1x12: 203,
    GF_MINI_2x12: 204,
};

export const getTbItmModelLbl = (tbItmModel, t) => {
    let _lbl = TbItmModelsLbls[tbItmModel];
    if (_lbl) {
        return _lbl;
    }

    _lbl = t(`cmd_tb_itm_models.${tbItmModel}`);
    TbItmModelsLbls[tbItmModel] = _lbl;

    return _lbl;
};

export const getTbItmModelsOrdered = (typ) => {
    if (typ === TbItmTypes.KABEL) {
        return [
            TbItmModels.KA_DA_10,
            TbItmModels.KA_DA_20,
            TbItmModels.KA_DA_50,
            TbItmModels.KA_DA_100,
            TbItmModels.KA_DA_200,
            TbItmModels.KA_DA_300,
            TbItmModels.KA_DA_500,
            TbItmModels.KA_DA_1000,
            TbItmModels.KA_DA_1500,
            TbItmModels.KA_DA_2000,
            TbItmLocations.UNK,
        ];
    } else if (typ === TbItmTypes.ROHR_VERLEGT) {
        return [
            TbItmModels.RO_50,
            TbItmModels.RO_110,
            TbItmModels.RO_SNRVE_1x7,
            TbItmModels.RO_SNRVE_2x7,
            TbItmModels.RO_SNRVE_8x7,
            TbItmModels.RO_SNRVE_22x7,
            TbItmModels.RO_SNRVE_3x12,
            TbItmModels.RO_SNRVE_7x12,
            TbItmLocations.UNK,
        ];
    } else if (typ === TbItmTypes.GF) {
        return [
            TbItmModels.GF_MICRO_1x4,
            TbItmModels.GF_MICRO_1x12,
            TbItmModels.GF_MINI_1x12,
            TbItmModels.GF_MINI_2x12,
            TbItmLocations.UNK,
        ];
    }

    return [TbItmLocations.UNK];
};

export const getIdentLbl = (tbItmTyp, tbItmNr, t) => {
    return `${tbItmNr}. ${getTbItmTypLbl(tbItmTyp, t)}`;
};

export const TbItmLocations = {
    STREET: 1,
    FOOTWAY: 2 /* GEHWEG */,
    PRIVATE: 3,
    PARK: 4,
    PARKING: 5,

    FUNDATIE_CASA: 10,
    FUNDATIE_GARD: 15,
    PERETE_CASA: 20,
    ALTELE: 25,

    KAB_AER: 30,
    KAB_VERLEGT: 35,
    KAB_ROHR: 40,

    UNK: 99,
};

export const getTbItmLocationsOrdered = (typ) => {
    if (typ === TbItmTypes.KABEL) {
        return [
            TbItmLocations.KAB_AER,
            TbItmLocations.KAB_VERLEGT,
            TbItmLocations.KAB_ROHR,

            TbItmLocations.UNK,
        ];
    } else if (typ === TbItmTypes.LOCH_WAND) {
        return [
            TbItmLocations.FUNDATIE_CASA,
            TbItmLocations.FUNDATIE_GARD,
            TbItmLocations.PERETE_CASA,
            TbItmLocations.ALTELE,

            TbItmLocations.UNK,
        ];
    }

    return [
        TbItmLocations.FOOTWAY,
        TbItmLocations.STREET,
        TbItmLocations.PRIVATE,
        TbItmLocations.PARK,
        TbItmLocations.PARKING,

        TbItmLocations.UNK,
    ];
};

const TbItmLocationLbls = {};

export const getTbItmLocationLbl = (tbItmLocation, t) => {
    let _lbl = TbItmLocationLbls[tbItmLocation];
    if (_lbl) {
        return _lbl;
    }

    _lbl = t(`cmd_tb_itm_locations.${tbItmLocation}`);
    TbItmLocationLbls[tbItmLocation] = _lbl;

    return _lbl;
};

export const TbItmStatus = {
    NOT_STARTED: 1,
    OPEN: 2,
    OPEN_GUARDED: 3,
    CLOSED: 4,
    CLOSED_GUARDED: 5,
    ASPHALT_TS: 6,
    READY: 8,
};

const TbItmStatusOrdered4Asphalt = [
    TbItmStatus.NOT_STARTED,
    TbItmStatus.OPEN,
    TbItmStatus.OPEN_GUARDED,
    TbItmStatus.CLOSED,
    TbItmStatus.CLOSED_GUARDED,
    TbItmStatus.ASPHALT_TS,
    TbItmStatus.READY,
];

const TbItmStatusOrdered4NotAsphalt = TbItmStatusOrdered4Asphalt.filter(
    (it) => it !== TbItmStatus.ASPHALT_TS
);

const TbItmStatusOrderedEasy = [TbItmStatus.NOT_STARTED, TbItmStatus.READY];

export const getTbItmStatusOrdered = (tbItmType, tbItmSurface) => {
    if (
        tbItmType === TbItmTypes.LOCH_WAND ||
        tbItmType === TbItmTypes.ROHR_VERLEGT ||
        tbItmType === TbItmTypes.KABEL ||
        tbItmType === TbItmTypes.GF ||
        tbItmType === TbItmTypes.SOCLU
    ) {
        return TbItmStatusOrderedEasy;
    }
    if (tbItmSurface === TbItmSurfaces.ASPHALT) {
        return TbItmStatusOrdered4Asphalt;
    }

    return TbItmStatusOrdered4NotAsphalt;
};

const TbItmStatusLbls = {};

export const getTbItmStatusLbl = (tbItmStatus, t) => {
    let _lbl = TbItmStatusLbls[tbItmStatus];
    if (_lbl) {
        return _lbl;
    }

    _lbl = t(`cmd_tb_itm_sts.${tbItmStatus}`);
    TbItmStatusLbls[tbItmStatus] = _lbl;

    return _lbl;
};

export const TbItmSurfaces = {
    ASPHALT: 1,
    PFLASTER: 2,
    PLATTEN: 3,
    UB: 4,
    MOSAIK: 5,
    RASEN: 6 /* GAZON */,

    CARAMIDA: 10,
    BETON: 12,
    PIATRA: 14,

    UNK: 99,
};

const TbItmSurfacesLbls = {};
export const getTbItmSurfacesOrdered = (typ) => {
    if (typ === TbItmTypes.LOCH_WAND) {
        return [
            TbItmSurfaces.CARAMIDA,
            TbItmSurfaces.BETON,
            TbItmSurfaces.PIATRA,
            TbItmSurfaces.UNK,
        ];
    } else if (typ === TbItmTypes.GRABEN || typ === TbItmTypes.GRUBE) {
        return [
            TbItmSurfaces.ASPHALT,
            TbItmSurfaces.PFLASTER,
            TbItmSurfaces.PLATTEN,
            TbItmSurfaces.UB,
            TbItmSurfaces.MOSAIK,
            TbItmSurfaces.RASEN,
            TbItmSurfaces.UNK,
        ];
    }

    return [
        TbItmSurfaces.ASPHALT,
        TbItmSurfaces.PFLASTER,
        TbItmSurfaces.PLATTEN,
        TbItmSurfaces.UB,
        TbItmSurfaces.MOSAIK,
        TbItmSurfaces.RASEN,
        TbItmSurfaces.CARAMIDA,
        TbItmSurfaces.BETON,
        TbItmSurfaces.PIATRA,
        TbItmSurfaces.UNK,
    ];
};

export const getTbItmSurfaceLbl = (tbItmSurface, t) => {
    if (!tbItmSurface) {
        return null;
    }

    let _lbl = TbItmSurfacesLbls[tbItmSurface];
    if (_lbl) {
        return _lbl;
    }

    _lbl = t(`cmd_tb_itm_surfaces.${tbItmSurface}`);
    TbItmSurfacesLbls[tbItmSurface] = _lbl;

    return _lbl;
};

export const calcAsphaltTS = (L, B, H) => {
    if (!L || !B || !H) {
        return null;
    }

    return Math.round((L * B * H * 23) / 10000);
};

export const calcAsphaltFS = (L, B, H) => {
    const _ts = calcAsphaltTS(L, B, H);
    if (!_ts) {
        return _ts;
    }

    return Math.round(_ts / 2);
};

export class TbItm {
    _id = "";
    _new = false;

    _typ = null;

    _desc = "";
    _nr = 1;

    _location = TbItmLocations.UNK;
    _surface = TbItmSurfaces.UNK;
    _model = TbItmModels.UNK;
    _reason = TbItmReasons.UNK;

    _status = TbItmStatus.NOT_STARTED;

    _laenge = null;
    _breite = null;
    _hoehe = null;

    _laengeA = null;
    _breiteA = null;
    _hoeheA = 10;

    _diameter = null; // used for LOCH_WAND
    _wand = null; // width wall; used for LOCH_WAND

    _beton = null;
    _comment = null;

    // TODO: lista de poze! -> poze inainte - dupa poate separat?

    constructor(id) {
        this._id = id;

        makeObservable(this, {
            _id: observable,
            _new: observable,

            _typ: observable,
            _desc: observable,
            _nr: observable,
            _location: observable,
            _surface: observable,
            _status: observable,
            _model: observable,
            _reason: observable,

            _laenge: observable,
            _breite: observable,
            _hoehe: observable,

            _laengeA: observable,
            _breiteA: observable,
            _hoeheA: observable,

            _diameter: observable,
            _wand: observable,
            _beton: observable,

            _comment: observable,

            id: computed,
            isNew: computed,

            typ: computed,
            desc: computed,
            nr: computed,
            location: computed,
            surface: computed,
            status: computed,
            model: computed,
            reason: computed,

            laenge: computed,
            breite: computed,
            hoehe: computed,

            laengeA: computed,
            breiteA: computed,
            hoeheA: computed,

            diameter: computed,
            wand: computed,

            beton: computed,

            comment: computed,
            toSrv: computed,

            asphaltTS: computed,
            asphaltFS: computed,

            myRemFiles: computed,
            myRemFileIds: computed,
            myRemImgIds: computed,
            myRemDocIds: computed,

            setTyp: action.bound,
            setNew: action.bound,

            setDesc: action.bound,
            setNr: action.bound,
            setLocation: action.bound,
            setSurface: action.bound,
            setStatus: action.bound,
            setModel: action.bound,
            setReason: action.bound,

            setLaenge: action.bound,
            setBreite: action.bound,
            setHoehe: action.bound,

            setLaengeA: action.bound,
            setBreiteA: action.bound,
            setHoeheA: action.bound,

            setDiameter: action.bound,
            setWand: action.bound,

            setBeton: action.bound,
            setComment: action.bound,

            fromSrv: action.bound,
        });
    }

    get id() {
        return this._id;
    }

    get isNew() {
        return this._new;
    }

    /**
     * see also TbItmTypes
     */
    get typ() {
        return this._typ ? Number(this._typ) : this._typ;
    }

    get desc() {
        return this._desc;
    }
    get nr() {
        return this._nr;
    }
    get location() {
        return this._location ? Number(this._location) : this._location;
    }
    get surface() {
        return this._surface ? Number(this._surface) : this._surface;
    }
    get status() {
        return this._status;
    }
    get model() {
        return this._model;
    }
    get reason() {
        return this._reason;
    }

    get laenge() {
        return this._laenge;
    }
    get breite() {
        return this._breite;
    }
    get hoehe() {
        return this._hoehe;
    }

    get laengeA() {
        return this._laengeA;
    }
    get breiteA() {
        return this._breiteA;
    }
    get hoeheA() {
        return this._hoeheA;
    }

    get diameter() {
        return this._diameter;
    }
    get wand() {
        return this._wand;
    }
    get beton() {
        return this._beton;
    }
    get comment() {
        return this._comment;
    }

    get asphaltTS() {
        const _laenge = this._laengeA || this._laenge;
        const _breite = this._breiteA || this._breite;
        const _hoehe = this._hoeheA || 10;

        if (_laenge > 0 && _breite > 0 && _hoehe > 0) {
            return calcAsphaltTS(_laenge, _breite, _hoehe);
        }

        return null;
    }

    get asphaltFS() {
        const _ts = this.asphaltTS;
        if (_ts) {
            return Math.round(_ts / 2);
        }

        return null;
    }

    get myRemFiles() {
        return orderBy(
            values(root.remFiles).filter(
                (it) =>
                    it.ownId === this._id && it.ownType === RemFileOwnerTypes.TB
            ),
            ["ts"],
            ["desc"]
        );
    }

    get myRemFileIds() {
        return this.myRemFiles.map((it) => it.id);
    }
    get myRemImgIds() {
        return this.myRemFiles
            .filter((it) => it.typ === "foto")
            .map((it) => it.id);
    }
    get myRemDocIds() {
        return this.myRemFiles
            .filter((it) => it.typ !== "foto")
            .map((it) => it.id);
    }

    setNew(val) {
        if (this._new !== val) {
            this._new = val;
        }
    }

    setTyp(val) {
        if (this._typ !== val) {
            this._typ = val;
        }
    }

    setNr(val) {
        if (this._nr !== val) {
            this._nr = val;
        }
    }

    setLocation(val) {
        if (this._location !== val) {
            this._location = val;
        }
    }

    setSurface(val) {
        if (this._surface !== val) {
            this._surface = val;
        }
    }

    setStatus(val) {
        if (this._status !== val) {
            this._status = val;
        }
    }

    setModel(val) {
        if (this._model !== val) {
            this._model = val;
        }
    }

    setReason(val) {
        if (this._reason !== val) {
            this._reason = val;
        }
    }

    setDesc(val) {
        if (this._desc !== val) {
            this._desc = val;
        }
    }

    setLaenge(val) {
        if (this._laenge !== val) {
            this._laenge = val;
        }
    }

    setBreite(val) {
        if (this._breite !== val) {
            this._breite = val;
        }
    }

    setHoehe(val) {
        if (this._hoehe !== val) {
            this._hoehe = val;
        }
    }

    setLaengeA(val) {
        if (this._laengeA !== val) {
            this._laengeA = val;
        }
    }

    setBreiteA(val) {
        if (this._breiteA !== val) {
            this._breiteA = val;
        }
    }

    setHoeheA(val) {
        if (this._hoeheA !== val) {
            this._hoeheA = val;
        }
    }

    setDiameter(val) {
        if (this._diameter !== val) {
            this._diameter = val;
        }
    }

    setWand(val) {
        if (this._wand !== val) {
            this._wand = val;
        }
    }

    setBeton(val) {
        if (this._beton !== val) {
            this._beton = val;
        }
    }

    setComment(val) {
        if (this._comment !== val) {
            this._comment = val;
        }
    }

    fromSrv(srvJson) {
        //console.log("fromSrv(srvJson)", srvJson);

        this._id = srvJson.id;
        this._typ = srvJson.typ;
        this._desc = srvJson.desc;
        this._nr = srvJson.nr;
        this._location = srvJson.location;
        this._surface = srvJson.surface;

        if (srvJson.status) {
            this._status = srvJson.status;
        }
        if (srvJson.model) {
            this._model = srvJson.model;
        }
        if (srvJson.reason) {
            this._reason = srvJson.reason;
        }

        this._laenge = srvJson.laenge;
        this._breite = srvJson.breite;
        this._hoehe = srvJson.hoehe;

        this._laengeA = srvJson.laengeA;
        this._breiteA = srvJson.breiteA;
        this._hoeheA = srvJson.hoeheA;

        this._diameter = srvJson.diameter;
        this._wand = srvJson.wand;

        this._beton = srvJson.beton;
        this._comment = srvJson.comment;
    }

    get toSrv() {
        return toJS(
            pick(this, [
                "typ",
                "desc",
                "nr",
                "location",
                "surface",
                "status",
                "model",
                "reason",

                "laenge",
                "breite",
                "hoehe",

                "laengeA",
                "breiteA",
                "hoeheA",

                "diameter",
                "wand",
                "beton",
                "comment",
            ])
        );
    }
}
